interface MutationConfig {
  ONLY_CLASS: MutationObserverInit;
  TEXT: MutationObserverInit;
}

enum CHUTE_STATUSES {
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
  OFF = "off",
  ACTIVATION_NEEDED = "activation-needed",
}

const MUTATION_CONFIG: MutationConfig = {
  ONLY_CLASS: { attributeFilter: ["class"] },
  TEXT: { characterData: true, subtree: true },
};

document.addEventListener("turbo:load", () => {
  if (!document.querySelector("table.map")) return;

  const chutes = document.querySelectorAll("td.chute");
  const packetsCounts = document.querySelectorAll(".packet-count.badge");

  packetsCounts.forEach(el => {
    changeLongPacketsCountsStyles(el);
  });
  setMutationObserver(chutes, hideParcelIcon, MUTATION_CONFIG.ONLY_CLASS);
  setMutationObserver(
    packetsCounts,
    changeLongPacketsCountsStyles,
    MUTATION_CONFIG.TEXT
  );

  if (document.querySelector(".map-aio")) {
    const extras = document.querySelectorAll(".chute-extras");

    setMutationObserver(
      extras,
      changeChuteIconColor,
      MUTATION_CONFIG.ONLY_CLASS
    );
  }
});

const setMutationObserver = (
  elements: NodeListOf<Element>,
  callbackFn: (el: Element, parameter?: any) => void,
  configuration: MutationObserverInit,
  callbackParameter?: any
) => {
  elements.forEach(el => {
    const composeObserver = new MutationObserver(mutations => {
      mutations.forEach(() => {
        callbackParameter ? callbackFn(el, callbackParameter) : callbackFn(el);
      });
    });

    composeObserver.observe(el, configuration);
  });
};

const hideParcelIcon = (td: Element) => {
  if (td.classList.contains(CHUTE_STATUSES.SUCCESS)) return;

  const parcelIcon = td.querySelector(".icon-parcel") as HTMLElement;

  while (parcelIcon.firstChild) {
    parcelIcon.removeChild(parcelIcon.firstChild);
  }
};

const changeChuteIconColor = (extraWithChange: Element) => {
  const chute = extraWithChange.closest("td.chute") as HTMLElement;
  const extras = chute.querySelectorAll(".chute-extras");

  const switchClasses = (addStatus: string, ...removeStatus: string[]) => {
    chute.classList.add(addStatus);
    chute.classList.remove(...removeStatus);
  };

  if (checkBranchesStatus(extras, CHUTE_STATUSES.ERROR)) {
    switchClasses(
      CHUTE_STATUSES.ERROR,
      CHUTE_STATUSES.SUCCESS,
      CHUTE_STATUSES.WARNING,
      CHUTE_STATUSES.OFF
    );
  } else if (checkBranchesStatus(extras, CHUTE_STATUSES.WARNING)) {
    switchClasses(
      CHUTE_STATUSES.WARNING,
      CHUTE_STATUSES.SUCCESS,
      CHUTE_STATUSES.OFF
    );
  } else {
    switchClasses(
      CHUTE_STATUSES.SUCCESS,
      CHUTE_STATUSES.ERROR,
      CHUTE_STATUSES.WARNING,
      CHUTE_STATUSES.OFF
    );
  }
};

const changeLongPacketsCountsStyles = (el: Element) => {
  if (window.innerWidth < 2000) return;

  const wrapper = el.closest(".chute-wrapper") as HTMLElement;
  if (!wrapper) return;
  
  const extras = wrapper.querySelector(".chute-extras") as HTMLElement;
  const chute = wrapper.closest(".chute");
  const digitCount = el.textContent.length;
  const sideCondition = (side: string) => {
    return chute.classList.contains(side) && digitCount >= 2;
  };
  const sideMargin = (side: string) => {
    const margin = side === "left" ? "ms" : "me";

    return digitCount >= 3 ? `${margin}-3` : `${margin}-2`;
  };

  extras.classList.toggle("packet-count-2-digit", digitCount === 2);
  extras.classList.toggle("packet-count-3-digit", digitCount >= 3);
  extras.classList.toggle(`${sideMargin("left")}`, sideCondition("left"));
  extras.classList.toggle(`${sideMargin("right")}`, sideCondition("right"));
  el.classList.toggle("packet-count-long", digitCount >= 2);
};

// Check status class for first and second branch inside chutes-wrapper in AIO map
const checkBranchesStatus = (
  extras: NodeListOf<Element>,
  className: string
) => {
  return (
    extras[0].classList.contains(className) ||
    extras[1].classList.contains(className)
  );
};
