import Styleguide from "@web-packeta/styleguide";

document.addEventListener("turbo:load", () => {
  Styleguide.initContextSwitch();
  Styleguide.initDateFilter();
  Styleguide.initFilterSelectMany(visitCallback);
  Styleguide.initFilterSelectOne(visitCallback);
  Styleguide.initSearch(visitCallback);
  Styleguide.addStickyOffset(".table-sticky thead", ".navbar");
});

const visitCallback = url => {
  Turbo.visit(url);
};
