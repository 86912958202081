import { Popover, Tooltip } from "bootstrap";
import consumer from "../../channels/consumer";
import Chargers from "./chargers";

document.addEventListener("turbo:load", () => {
  const maps = document.querySelectorAll("table.map");

  if (!maps.length || hasChild(maps[0], ".map-config")) return;

  enablePopovers();

  maps.forEach(map => {
    const mapIds = map.dataset.levelIds;
    const levelIdsSplit = mapIds.split(",");

    levelIdsSplit.forEach(levelId => {
      createRobotPositionConsumer(levelId, levelId, map);
      createLevelMapConsumer(levelId);
    });

    fixChargeBorders(map);
  });
});

const createRobotPositionConsumer = (mapIds, levelId, map) => {
  const body = document.querySelector("body");
  body.classList.add("position-relative");

  consumer.subscribeTo(
    { channel: "RobotPositionsChannel", level_id: levelId },
    {
      received(data) {
        if (data) {
          data.forEach(robot => {
            const currentRobot = document.querySelector(
              `.robot[data-id="${robot.id}"]`
            );
            const tableCell = document.querySelector(
              `.map[data-level-ids="${mapIds}"] td[data-x="${robot.x}"][data-y="${robot.y}"]`
            );
            const mapRotatedRight = map.classList.contains("map-rotate-right");
            const halfSizeRobot = mapRotatedRight ? 9 : 7.5;
            const divisor = mapRotatedRight ? 3.3 : 3;
            const centerTopOffset = mapRotatedRight ? 4 : 1;
            let domRect;
            let top;
            let left;
            let width;
            let height;
            let topPosition;
            let leftPosition;

            if (robot.type === "status") {
              currentRobot?.classList.toggle("error", robot.error);
              currentRobot?.classList.toggle("d-none", robot.hidden);
            } else if (robot.type === "position") {
              if (tableCell) {
                domRect = tableCell.getBoundingClientRect();
                top = domRect.top + window.scrollY;
                left = domRect.left + window.scrollX;
                width = domRect.width;
                height = domRect.height;
                topPosition =
                  top + height / divisor - halfSizeRobot + centerTopOffset;
                leftPosition = left + width / divisor - halfSizeRobot;
              }

              if (currentRobot && tableCell) {
                const packetIcon = currentRobot.querySelector(".fa-box");
                const robotTooltip =
                  currentRobot.getAttribute("data-bs-toggle");

                if (Chargers.initialized()) {
                  window.chargers.updateChargers(tableCell, robot.id);
                }

                if (!robotTooltip) {
                  currentRobot.setAttribute("data-bs-toggle", "tooltip");
                  currentRobot.setAttribute(
                    "data-bs-original-title",
                    robot.tooltip
                  );

                  window.tooltips.push(new Tooltip(currentRobot));
                }

                if (robot.packet) {
                  if (!packetIcon) {
                    const i = document.createElement("i");

                    i.classList.add(
                      "fas",
                      "fa-box",
                      "fa-stack-1x",
                      "small-font",
                      "z-index-5",
                      "icon-packet",
                      "position-absolute",
                      "robot-overlay-position"
                    );
                    currentRobot.appendChild(i);
                  }
                } else {
                  if (packetIcon) {
                    setTimeout(() => packetIcon.remove(), 1000);
                  }
                }

                if (currentRobot.parentElement.tagName.toLowerCase() === "td") {
                  currentRobot.classList.add("position-absolute");
                  body.appendChild(currentRobot);
                }

                currentRobot.style.top = `${topPosition}px`;
                currentRobot.style.left = `${leftPosition}px`;
              } else if (!currentRobot && tableCell) {
                const newRobotIcon = `<i class="fas fa-vacuum-robot robot position-absolute success" data-bs-toggle="tooltip"
                 data-bs-original-title="${robot.tooltip}"
                 data-id="${robot.id}"><i class="fas robot-level-arrow
                 fa-arrow-${robot.level_upper ? "up" : "down"}
                 "></i><span class="robot-error">`;

                body.insertAdjacentHTML("beforeend", newRobotIcon);
                const newCurrentRobot = document.querySelector(
                  `.robot[data-id="${robot.id}"]`
                );

                if (newCurrentRobot) {
                  newCurrentRobot.style.top = `${topPosition}px`;
                  newCurrentRobot.style.left = `${leftPosition}px`;

                  window.tooltips.push(new Tooltip(newCurrentRobot));
                }
              }
            }
          });
        }
      },
    }
  );
};

const setLevelStats = (data, levelId) => {
  const stats = data.level.stats;

  for (const stat in stats) {
    const statSpan = document.querySelector(
      `.count.${stat}[data-level-id="${levelId}"]`
    );

    if (statSpan) {
      statSpan.innerText = stats[stat];
    }
  }
};

const setChutes = data => {
  const chutes = data.chutes;

  if (chutes) {
    chutes.forEach(chute => {
      const currentChute = document.querySelector(
        `[data-code="${chute.code}"]`
      );
      const parcelIcon = currentChute.querySelector(".icon-parcel");
      const routingIcon = currentChute.querySelector(".icon-routing");
      const packetCount = currentChute.querySelector(".packet-count");
      const innerHtml = (element, html) => {
        element.innerHTML = html;
      };

      if (currentChute) {
        chute.state_class === "off"
          ? packetCount.classList.add("d-none")
          : (packetCount.innerText = chute.packets_count);

        parcelIcon &&
          innerHtml(parcelIcon, chute.parcel ? chute.type_icon : "");

        routingIcon &&
          chute.routing_icon &&
          innerHtml(routingIcon, chute.routing_icon);

        const currentChuteClassName = currentChute.className;
        const regex = /\b(?:off|warning|success|error|activation-needed)\b/gi;
        currentChute.className = currentChuteClassName.replace(
          regex,
          chute.state_class
        );
      }
    });
  }
};

const replaceElClasses = (el, regex, newClass) => {
  el.className = el.className.replace(regex, newClass);
};

const setLoadingTerminals = data => {
  const loadingTerminals = data.loading_terminals;

  if (loadingTerminals) {
    loadingTerminals.forEach(terminal => {
      const currentTerminal = document.querySelector(
        `td.loading-terminal[data-code="${terminal.code}"]`
      );

      if (currentTerminal) {
        const userClassList =
          currentTerminal.querySelector(".user i").classList;
        const deviceClassList =
          currentTerminal.querySelector(".device i").classList;
        const code = currentTerminal.querySelector(".code");
        const packetsCount = currentTerminal.querySelector(".packet-count");
        const regex = /\b(?:off|active|warning|activation-needed|error)\b/gi;
        const toggleClass = (classList, condition) => {
          classList.toggle("active", condition);
          classList.remove("off");
        };

        replaceElClasses(code, regex, terminal.status_class);
        packetsCount.innerHTML = terminal.packets_count;

        toggleClass(userClassList, terminal.user);
        toggleClass(deviceClassList, terminal.device);

        replaceElClasses(
          currentTerminal.querySelector(".user i"),
          regex,
          terminal.status_class
        );
        replaceElClasses(
          currentTerminal.querySelector(".device i"),
          regex,
          terminal.status_class
        );
        replaceElClasses(
          currentTerminal.querySelector(".loading-terminal-icon"),
          regex,
          terminal.status_class
        );
        replaceElClasses(
          currentTerminal.querySelector(".count"),
          regex,
          terminal.status_class
        );
      }
    });
  }
};

const setServiceAreas = data => {
  const serviceAreas = data.service_areas;

  if (serviceAreas) {
    serviceAreas.forEach(area => {
      const currentArea = document.querySelector(`[data-code="${area.code}"]`);

      currentArea && currentArea.classList.toggle("with-robot", area.robot);
    });
  }
};

const createLevelMapConsumer = levelId => {
  consumer.subscribeTo(
    { channel: "LevelMapChannel", level_id: levelId },
    {
      received(data) {
        if (data.refresh) {
          return window.location.reload();
        }

        setLevelStats(data, levelId);
        setChutes(data);
        setLoadingTerminals(data);
        setServiceAreas(data);
      },
    }
  );
};

const enablePopovers = () => {
  const popoverTriggerList = [].slice.call(
    document.querySelectorAll("[data-popover-enabled='true']")
  );

  popoverTriggerList.map(popoverTriggerEl => {
    const title = popoverTriggerEl.dataset.popoverTitle;

    return new Popover(popoverTriggerEl, {
      title: title,
      trigger: "focus",
      sanitize: false,
    });
  });
};

const hasChild = (parentEl, childEl) => {
  const child = parentEl.querySelector(childEl);

  return !!child;
};

const fixChargeBorders = map => {
  const chargeMap = new Map();

  map.querySelectorAll(".charge").forEach(charge => {
    const x = parseInt(charge.getAttribute("data-x"), 10);
    const y = charge.getAttribute("data-y");

    !chargeMap.has(y) && chargeMap.set(y, new Map());
    chargeMap.get(y).set(x, charge);

    const nextCharge = chargeMap.get(y)?.get(x + 1);

    nextCharge && charge.classList.add("border-top-0");
  });
};
